* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

a {
  text-decoration: none !important;
}

canvas {
  outline: none;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}
.new_table_faham th {
  height: 46px !important;
  background-color: #373747 !important;
  color: #ffffff !important;
  border-bottom: none !important;
  border-color: inherit !important;
  border-style: solid !important;
  border-width: 0 !important;
  text-align: left;
  border-radius: 2px 2px 0 0;
  border-collapse: separate;
  border-spacing: 0;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.new_table_faham {
  border: 0 !important;
  color: #fff !important;
}

.new_table_faham > tbody > tr > td {
  border-bottom: 1px solid #373747 !important;
  padding-top: 25px !important;
  padding-bottom: 28px !important;
  transition: background 0.3s !important;
  position: relative !important;
  padding: 16px 16px !important;
  overflow-wrap: break-word !important;
  word-break: break-all !important;
}

/*  JSON Table Styling ---------------- */

.json-table {
  display: table !important;
  border-spacing: 2px !important;
  color: white;
  border-collapse: collapse !important;
  width: 100% !important;
  table-layout: fixed !important;
  font-size: 14px !important;
  background-color: #1c1c24 !important;
}

.json-table tr {
  display: table-row !important;
  vertical-align: inherit !important;
}

.json-table td {
  padding: 0.5rem !important;
  text-align: left !important;
  border: 1px solid #525268 !important;
}

/* ------------------------------ */

.ant-spin-dot-item {
  background-color: #007fd9;
}

.actionsTable {
  overflow-y: scroll;
  height: 20rem;
}

.google-link {
  width: 100%;
  border-radius: 5px;
  height: 32px;
}

::-webkit-scrollbar {
  width: 0.7em;
  height: 0.7em;
}
:hover::-webkit-scrollbar-thumb {
  background-color: #525268;
  border-radius: 0.5em;
}

::-webkit-scrollbar-thumb {
  background-color: #525268;
  border-radius: 0.5em;
}
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px inherit;
  background-color: transparent;
  z-index: 100 !important;
  border-radius: 0.5em;
}
body::-webkit-scrollbar-track {
  background-color: #13131a;
}
.ant-select-multiple .ant-select-selection-item {
  background: #44444b;
}
.ant-select-multiple {
  overflow: auto;
  overflow-x: hidden;
  height: fit-content;
}

::selection {
  background: #c4c4c430;
}
::-moz-selection {
  background: #c4c4c430;
}

.ant-tooltip-arrow-content,
.ant-tooltip-inner {
  background: #373747;
  font-family: Roboto-Regular !important;
  font-size: 1.07rem;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0px;
  text-align: left;
}

.ant-notification-notice {
  background: #373747;
  color: #fff;
  cursor: pointer;
}

.ant-notification{
  z-index: 1060;
}

.ant-notification-notice-message {
  color: #fff;
}

.ant-notification-close-x {
  color: #fff;
}

input[disabled="disabled"],
select[disabled="disabled"],
textarea[disabled="disabled"] {
  opacity: 0.5 !important;
}

.pointer {
  color: cornflowerblue;
  cursor: pointer;
}

.cr-pointer {
  cursor: pointer;
}
.cross-add-btn {
  margin: 12px 6px 0 0px !important;
  padding: 0px 5px 0px 5px !important;
}
.switch-wrapper {
  display: flex;
  margin-top: 1rem;
}
.switch-wrapper label {
  margin-bottom: 0;
  margin-left: 20px;
}
.mt-40 {
  margin-top: 40px;
}
.main label {
  margin-bottom: 20px;
  color: #bdbdbd;
  font-size: 13px;
}
.asset-separator {
  border: 1px solid #bdbdbd;
}
.add-rules-title {
  color: #fff;
  font-size: 24px;
}
.disabled-ip input {
  opacity: 0.5 !important;
}

.ds-flex {
  display: flex;
  margin-top: 10px;
}

.ant-table-expanded-row-fixed div {
  color: #fff;
}

.text-block-img {
  text-align: center;
  padding-top: 57px;
}

.flex-wrap-d-flex {
  display: flex;
  flex-wrap: wrap;
}

.make-child-center {
  display: flex;
  justify-content: center;
  margin-bottom: 80px;
  margin-top: 40px;
}

.success-link {
  margin-right: 5px;
  padding: 5px;
  background: #3dd598 !important;
  border-color: #3dd598 !important;
  border-radius: 8px;
  cursor: pointer;
}

.danger-link {
  background: #d74444 !important;
  border-color: #d74444 !important;
  margin-right: 5px;
  padding: 5px;
  border-radius: 5px;
  cursor: pointer;
}

.make-child-center img {
  width: 100px;
}

.text-center-img {
  text-align: center;
  padding: 12px;
}

td {
  word-break: break-all;
}

.p-rleative-block {
  position: relative;
}

.div.jsoneditor-tree {
  height: 400px !important;
}

.common-alert .ant-alert {
  position: fixed;
  right: 0px;
  z-index: 1000000000000000000;
  top: 0px;
}

.jsoneditor-menu {
  background-color: #373747 !important;
}

.task-modal .ant-collapse {
  border: 0 !important;
}

.task-modal .ant-collapse-header {
  color: #fff !important;
}

html,
body {
  max-width: 100%;
  font-size: 14px;
  overflow-x: hidden;
}

.selected-files {
  color: #fff !important;
  margin-right: 4px;
}

.comments-files-wrapper {
  padding: 8px;
  background: #373747;
  margin: 4px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  width: 24%;
  position: relative;
}

.comments-files-wrapper div {
  position: absolute;
  right: 10;
}

.comments-files-wrapper a {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 30%;
}

.selected-div {
  padding: 8px;
  background: #373747;
  margin: 4px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  width: 100%;
  position: relative;
  overflow: hidden;
}

.selected-div div {
  position: absolute;
  right: 10px;
}

.selected-div a {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 30%;
}

.selected-div-row {
  display: flex;
  flex-wrap: wrap;
}

.evidence-error-div {
  border: 1px solid #fc5a5a;
  color: #fc5a5a;
}

.evidence-error-div a {
color: #fc5a5a !important;
}

.evidence-error-div div svg {
  fill: #fc5a5a !important;
  }

.ant-table-tbody > tr > td {
  color: #ffffff;
}

.ant-modal-header {
  border-top-left-radius: 14px !important;
  border-top-right-radius: 14px !important;
}

.ant-picker-cell-inner {
  text-align: center;
}

.ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single)::before,
.ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single)::before {
  background: #373737;
}

.ant-picker-range .ant-picker-active-bar {
  background: #373737 !important;
}

.ant-picker-clear {
  color: #fff !important;
  background: #1c1c24 !important;
}

.ant-picker-clear:hover {
  color: #fff;
}

.btn-item-plus {
  background: #3dd598;
  color: #fff;
  padding: 6px;
  margin-top: 38px;
  margin-left: 8px;
  width: 24px;
  cursor: pointer;
  border-radius: 5px;
  z-index: 4;
}

.react-grid-item > .react-resizable-handle {
  opacity: 0;
}

.react-grid-item:hover > .react-resizable-handle {
  opacity: 1;
}

.react-grid-item > .react-resizable-handle::after,
.react-grid-item > .react-resizable-handle::before {
  content: "";
  position: absolute !important;
  right: 0 !important;
  bottom: 3px !important;
  width: 1px !important;
  height: 22px !important;
  background-color: #fff !important;
  border: 0 !important;
}
.react-grid-item > .react-resizable-handle::before {
  right: 4px !important;
}

.react-grid-item > .react-resizable-handle.react-resizable-handle-e {
  transform: rotate(0) !important;
  right: 10px !important;
  background-image: none !important;
}

.react-grid-item > .react-resizable-handle.react-resizable-handle-s {
  transform: rotate(90deg) !important;
  bottom: 10px !important;
  background-image: none !important;
}

.ant-picker-panel-container {
  background-color: #fff;
}
.ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single)::before {
  background-color: #373737 !important;
}

.ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover::before {
  background: #373737 !important;
  /* border: 1px dotted; */
}
.ant-picker-cell-in-view.ant-picker-cell-in-range::before {
  background: #373737 !important;
}
.ant-picker-cell-disabled::before {
  background: #525268 !important;
}

.ant-picker-input > input {
  color: #fff;
}
.ant-picker-separator {
  color: #fff;
}

.ant-picker-focused .ant-picker-separator {
  color: #fff;
}

.ant-picker-suffix {
  color: #fff;
}

.ant-picker-focused {
  border-color: #13131a;
}

.ant-table-column-sorters {
  display: flex !important;
  justify-content: flex-start !important;
  align-items: center !important;
}

.ant-dropdown-menu-item,
.ant-dropdown-menu-submenu-title {
  display: flex;
}

.ant-dropdown-menu-submenu-popup > ul {
  padding: 0;
  border: 1px solid #525268;
  border-radius: 3px;
}

/*
.ant-dropdown-menu-item:first-child{
  z-index: 1 !important;
*/
.react-grid-layout {
  transition: none;
  overflow: scroll;
}
.react-grid-item {
  transition-property: none;
}

/* Faham Added*/
.UxBID {
  border: 0px !important;
  border-bottom: 1px solid #44444f !important;
}

.ant-menu-item-icon {
  padding-top: 0px !important;
}

.cLpMNz {
  border-left: 0px !important;
}

.fpbmQs {
  border-radius: 10px;
}

.ivkOPC {
  width: 280px !important;
  border-right: 1px solid #525268;
  min-height: 90vh !important;
  height: 100% !important;
}

.jmOLjt div[role="tabpanel"] {
  min-height: 90vh !important;
  height: 100% !important;
}

.react-grid-item {
  transition-property: none;
}

div.react-grid-layout {
  overflow-x: hidden;
  min-height: unset !important;
}

div.grid-item {
  background: #1c1c24;
}
.remove-widget-icon {
  z-index: 9999;
}

.ant-input,
.ant-input-suffix svg {
  color: #bdbdbd;
}

.detailSection {
  text-align: left;
  word-break: break-all;
}

.detailSection::-webkit-scrollbar {
  display: none;
}

.renderedImage img {
  display: none;
}

.customDropdown .ant-select {
  height: 38px !important;
  border: black !important;
  padding: 5px;
}

.customDropdown .ant-select-selector {
  cursor: pointer !important;
}

.customDropdown .ant-select .ant-select-arrow {
  margin-top: -10px;
}

.administrationContentWrapper {
  height: 100%;
  overflow: hidden;
  overflow-y: scroll;
  padding: 0rem 1rem !important;
}

.administrationContentWrapper .ant-layout {
  position: sticky;
  top: -1px;
  background-color: #2c2c38;
  padding-bottom: 10px;
  z-index: 9;
}

.administrationContentWrapper .ant-table-content {
  height: auto !important;
  overflow-y: auto;
  padding-bottom: 1rem;
}

@media screen and (max-width: 1700px) and (min-width: 768px) {
  .administrationContentWrapper .ant-table-content {
    height: auto !important;
    overflow-y: auto;
  }
  .administrationContentWrapper {
    overflow-y: auto !important;
    padding: 0rem 1rem !important;
    height: 100%;
  }
}

.row-selected {
  background-color: #2c2c38 !important;
  font-weight: 900 !important;
  box-shadow: inset 0 -1px 0 0 rgb(100 121 143 / 12%) !important;
}

.image_picker img {
  width: 5rem !important;
  height: 5rem !important;
  margin: 0.5rem 0 !important;
  border: none !important;
}

.image_picker .checked .icon {
  width: 2rem !important;
  height: 2rem !important;
}

.ant-checkbox-group {
  padding: 1rem 0;
}

.ant-checkbox-group-item > span {
  color: red;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #007fd9;
  border-color: #007fd9;
}

/* Toastify Toast Styling */

.Toastify__toast-container
  .Toastify__toast--default
  .Toastify__progress-bar-theme--dark {
  background: var(--toastify-color-progress-success);
}

/* Widget Type Table Styling */

.widgetTable {
  color: #fff !important;
  border-collapse: collapse !important;
  overflow-x: scroll !important;
}

.widgetTable > tr > th {
  text-align: center;
  background-color: #373747;
  color: white;
}

.widgetTable > tr > th:first-child {
  border-top-left-radius: 10px;
}

.widgetTable > tr > th:last-child {
  border-top-right-radius: 10px;
}

.widgetTable td {
  text-align: center;
}

.sp-nice-scroll-style::-webkit-scrollbar {
  width: 4px;
  position: absolute;
}

.sp-nice-scroll-style::-webkit-scrollbar-thumb {
  border-radius: 10px;
  box-shadow: inset 0 0 5px #ccc;
  background: #f7fdfb;
  position: relative;
}

.sp-nice-scroll-style::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #f7fdfb;
  border-radius: 0;
  background: #f7fdfb;
}

.truncate {
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}

.noHover {
  pointer-events: none !important;
}
.ant-select-single.ant-select-show-arrow .ant-select-selection-item {
  display: block;
  line-height: 25px !important;
}

.ant-select-selector .ant-select-selection-placeholder {
  line-height: 3px !important;
}

/* Custom Widget Table Styling */

.customWidgetViewTable {
  color: #fff;
  background-color: #1c1c24;
  color: #fff !important;
  border-collapse: collapse !important;
  width: 980px !important;
  margin: 0 auto;
  overflow-x: scroll;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.customWidgetViewTable > tbody > tr > th {
  padding-left: 1.78rem !important;
  padding-right: 1.78rem !important;
  height: 3.28rem !important;
  background-color: #373747;
  color: #ffffff;
  border-bottom: none;
  text-align: center;
  background-color: #373747;
  color: white;
  width: 980px !important;
}

.customWidgetViewTable > tbody > tr > td {
  text-align: center;
  padding: 1.78rem;
}

.customWidgetViewTable > tbody > tr > th:first-child {
  border-top-left-radius: 10px;
}

.customWidgetViewTable > tbody > tr > th:last-child {
  border-top-right-radius: 10px;
}

.customWidgetViewTable > tbody {
  display: block !important;
  height: 400px !important;
  overflow-y: auto !important;
  overflow-x: auto !important;
}

.customWidgetViewTable tbody::-webkit-scrollbar {
  width: 0.5em;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.customWidgetViewTable input {
  font-size: 1rem;
  outline: none;
  text-align: left;
  color: #ffffff;
  border-radius: 5px;
  padding: 8px 15px;
  background: border-box;
  background-color: #1c1c24;
  border: 1px solid rgba(255, 255, 255, 0.36);
}

.sketch-picker {
  width: 60% !important;
  margin-top: 1rem !important;
}

div.sketch-picker > div:first-child {
  padding-bottom: 50% !important;
}

@keyframes blinking {
  0% {
    color: #ff3d50;
  }
  /* ADD MORE KEYFRAMES IF YOU WANT
  50% { color: #55d66b; } */
  100% {
    color: white;
  }
}

/* (B) ATTACH BLINKING KEYFRAMES TO TEXT */
#demo {
  font-size: 1.3em;
  font-weight: bold;
  animation: blinking 1s infinite;
}

/* Ant Design Pagination Component Styling */

.ant-pagination {
  padding-top: 2rem;
}

.pagination-row .ant-pagination .ant-pagination-item-link {
  background-color: #1c1c24 !important;
  color: #fff !important;
  border: none !important;
  border-radius: 5px !important;
}

.pagination-row .ant-pagination .ant-pagination-item {
  background-color: #1c1c24 !important;
  color: #fff !important;
  border: none !important;
  border-radius: 5px !important;
}

.pagination-row .ant-pagination .ant-pagination-item-active {
  background-color: #1890ff !important;
  color: #fff !important;
  border: none !important;
  border-radius: 5px !important;
}

.ql-editor.ql-blank::before {
  font-style: normal !important;
}

.htmlToolTip .ant-tooltip-inner::-webkit-scrollbar {
  display: none !important;
}

.typed-cursor {
  opacity: 0 !important;
}

.ant-modal-wrap.ant-modal-confirm-centered.ant-modal-centered {
  z-index: 9999;
}

.task-selection-checkbox .ant-checkbox-inner {
  width: 14px !important;
  height: 14px !important;
}

.container_checkbox {
  width: 350px;
}

.container_checkbox.inc_width {
  width: 700px;
}
.apexcharts-xcrosshairs, .apexcharts-ycrosshairs{
  display: none !important;
}


.nowrap{
  white-space: nowrap;
}