* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

a {
  text-decoration: none;
}

@font-face {
  font-family: 'Roboto-Regular';
  src: url('../assets/fonts/Roboto-Regular.ttf');
}
@font-face {
  font-family: 'Roboto-Bold';
  src: url('../assets/fonts/Roboto-Bold.ttf');
}
@font-face {
  font-family: 'Roboto-Medium';
  src: url('../assets/fonts/Roboto-Medium.ttf');
}
@font-face {
  font-family: 'Roboto-Light';
  src: url('../assets/fonts/Roboto-Light.ttf');
}

.margin-10 {
  margin: 10px;
}

div.jsoneditor-value{
  color: #fff !important;
}
div.jsoneditor-field{
  color: #fff !important;
}

.ant-dropdown-menu-item:hover {
 background-color:  #525268;
 border-color:#525268 !important ;
}

.ant-btn .anticon.anticon-plus > svg{
  shape-rendering:auto !important;
}

.highcharts-credits {
  display: none !important;
}


.gsfBackground {
  border-color: #b9ed6b;
  font-size: 13px;
  margin-bottom: 3px;
}