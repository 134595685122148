.errorInput {
  font-size: 1.07rem;
  width: 100%;
  outline: none;
  text-align: left;
  color: white;
  border-radius: 5px;
  padding: 0px 11px;
  background: border-box;
  border: 1px solid #ffffff5c;
}
.errorInput:focus {
  border: 1px solid #fc5a5a;
}
.noErrorInput {
  font-size: 1.07rem;
  width: 100%;
  outline: none;
  text-align: left;
  color: white;
  border-radius: 5px;
  padding: 0px 11px;
  background: border-box;
  border: 1px solid #ffffff5c;
}
.noErrorInput:focus {
  border: 1px solid #007FD9;
}
/* input:-webkit-autofill {
  color: #2a2a2a;
} */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: 1px solid #007FD9 !important;
  -webkit-text-fill-color:#ffffff !important;
  transition: background-color 5000s ease-in-out 0s !important;
  -webkit-box-shadow: 0 0 0px 1000px #1c1c24 inset !important;
}
