/* .ant-select-item-option-state {
  display: none;
}

.rc-virtual-list-holder {
  max-height: 310px !important;
  overflow-y: unset !important;
}
.rc-virtual-list-holder-inner {
  transform: none !important;
  overflow-y: scroll !important;
} */

.rc-virtual-list-holder  > div:first-of-type {
  overflow: auto !important;
}
.css-2b097c-container {
  position: relative;
  /* box-sizing: border-box; */
  width: 300px;
  background-color: #1c1c24 !important;
  /* height: 30px !important; */
  border-radius: 5px !important;
}

.css-26l3qy-menu {
  display: none !important;
}

.css-1rhbuit-multiValue {
  background-color: #525268 !important;
  border-radius: 0.5em !important;
}
.css-12jo7m5{
    color: #FFFFFF !important;
    font-family: Roboto-Regular !important;
    font-size: 1.07rem !important;
    font-style: normal !important;
    /* font-weight: 400 !important;
    line-height: 22px !important; */
    text-align: left !important;
    /* padding: 0px 5px !important; */

}
.css-xb97g8, .css-xb97g8:hover{
  border: none;
  outline: none;
  background-color: #FFFFFF00 !important;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  cursor: pointer;
}
.css-yk16xz-control:hover {
  /* border-color: #FFFFFF00 !important; */
  /* background-color: #FFFFFF00 !important; */
}
.css-1hb7zxy-IndicatorsContainer:hover{
  border-color: #FFFFFF00 !important;
  background-color: #FFFFFF00 !important;
}
.css-1pahdxg-control,.css-1pahdxg-control:hover,.css-1pahdxg-control::selection {
  border-color: #7d2f2f00 !important;
  background-color: #FFFFFF00 !important;
  box-shadow: none !important;
    font-size: 1rem !important;

    border-radius: 5px !important;
    background: border-box !important;
    border: 1px solid #ffffff5c !important;
    min-height: 23px !important;
    /* max-height: 33px !important; */
}
.css-yk16xz-control, .css-yk16xz-control:hover, .css-yk16xz-control::selection {
  background-color: #1c1c24 !important;
  min-height: 23px !important;
  /* max-height: 38px !important; */
  box-sizing: border-box !important;
  border-radius: 5px !important;
  border: 1px solid #ffffff5c !important;
}

.css-tlfecz-indicatorContainer,.css-1okebmr-indicatorSeparator,.css-1gtu0rj-indicatorContainer{
display: none !important;
}
.jDgzrg{
  margin-top: -0.6rem !important;
  margin-bottom: -0.2rem !important;
}